import React, { Component } from "react";
import { connect } from "react-redux";
// Actions
import { getItems } from "../../redux/actions/Marketplace";
import { claimJob, markAsDelivered } from "../../redux/actions/Delivery";
import { setSelfService } from "../../redux/actions/User";
// Constants
import i18nstring, { strings } from "../../i18n";
// Components
import { SelfServiceTable } from "../organisms/Table";
import { Button } from "../atoms/Button";
// Themes
import "./PageStyles.scss";
import { selfServiceHeaders } from "../../model/Table";

class SelfService extends Component {

  constructor(props){
    super(props);
    this.state = {
      activeRowIndex: -1
    }
  }

  componentDidMount() {
    if (!this.props.itemsFetched) {
      this.props.attemptGetItems();
    }
  }

  onRowClick = (index) => {
    this.setState({
      activeRowIndex: index
    })
  }

  render() {
    const dropoffOffer = this.props.user.offers.DROPOFF;
    return (
      <div>
        <div className="toggle_self_service_container">
         </div>
        <div className="main_container_table">
          <div className="table_title">
            {i18nstring(strings.ACTIVE_PENDING_DONATIONS)}
          </div>
          <SelfServiceTable
            activeRowIndex={this.state.activeRowIndex}
            onRowClick={this.onRowClick}
            approvalStatus={true}
            headers={selfServiceHeaders}
            items={this.props.items}
            isSelfService={this.props.user.isSelfService}
            userId={this.props.user.id}
            attemptClaimJob={this.props.attemptClaimJob}
            attemptMarkAsDelivered={this.props.attemptMarkAsDelivered}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  items: state.marketplace.items,
  itemsFetched: state.marketplace.itemsFetched,
  user: state.account.user
});

const mapDispatchToProps = dispatch => {
  return {
    attemptGetItems: () => dispatch(getItems()),
    attemptClaimJob: jobId => dispatch(claimJob(jobId)),
    attemptMarkAsDelivered: id => dispatch(markAsDelivered(id)),
    toggleSelfService: isSelfService => dispatch(setSelfService(isSelfService))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfService);
