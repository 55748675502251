exports.ItemAction = {
  REQUEST: 'REQUEST',
  CLAIM: 'CLAIM',
  PICK_UP: 'PICK_UP',
  DELIVER: 'DELIVER',
  CANCEL: 'CANCEL',
}

exports.ItemTimeBlock = {
  AM: 'AM',
  PM: 'PM',
}

exports.ItemDayScheduled = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
}

exports.ItemRepeat = {
  NONE: 'NONE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
}

exports.ItemState = {
  // active === true
  POSTED: 'POSTED',
  REQUESTED: 'REQUESTED',
  CLAIMED: 'CLAIMED',
  PICKED_UP: 'PICKED_UP',
  // active === false
  DELIVERED: 'DELIVERED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
}

exports.ItemRejectedReason = {
  INCORRECT_WEIGHT: 'INCORRECT_WEIGHT',
}

exports.ItemCategory = {
  DROPOFF: 'DROPOFF',
  PICKUP: 'PICKUP',
}

exports.VegetablesList = [
  { key: 'Asparagus', label: 'Asparagus', value: 'Asparagus', id: 0 },
  { key: 'Beans, dried', label: 'Beans, dried', value: 'Beans, dried', id: 1 },
  { key: 'Beets', label: 'Beets', value: 'Beets', id: 2 },
  { key: 'Broccoli', label: 'Broccoli', value: 'Broccoli', id: 3 },
  {
    key: 'Brussel Sprouts',
    label: 'Brussel Sprouts',
    value: 'Brussel Sprouts',
    id: 4,
  },
  { key: 'Cabbage', label: 'Cabbage', value: 'Cabbage', id: 5 },
  { key: 'Carrots', label: 'Carrots', value: 'Carrots', id: 6 },
  { key: 'Cauliflower', label: 'Cauliflower', value: 'Cauliflower', id: 7 },
  { key: 'Celery', label: 'Celery', value: 'Celery', id: 8 },
  { key: 'Corn', label: 'Corn', value: 'Corn', id: 9 },
  { key: 'Cucumbers', label: 'Cucumbers', value: 'Cucumbers', id: 10 },
  { key: 'Eggplant', label: 'Eggplant', value: 'Eggplant', id: 11 },
  { key: 'Garlic', label: 'Garlic', value: 'Garlic', id: 12 },
  { key: 'Green beans', label: 'Green beans', value: 'Green beans', id: 13 },
  { key: 'Greens', label: 'Greens', value: 'Greens', id: 14 },
  { key: 'Herbs', label: 'Herbs', value: 'Herbs', id: 15 },
  { key: 'Leeks', label: 'Leeks', value: 'Leeks', id: 16 },
  { key: 'Mushrooms', label: 'Mushrooms', value: 'Mushrooms', id: 17 },
  { key: 'Okra', label: 'Okra', value: 'Okra', id: 18 },
  { key: 'Onions', label: 'Onions', value: 'Onions', id: 19 },
  { key: 'Parsnips', label: 'Parsnips', value: 'Parsnips', id: 20 },
  { key: 'Peanuts', label: 'Peanuts', value: 'Peanuts', id: 21 },
  { key: 'Peas', label: 'Peas', value: 'Peas', id: 22 },
  {
    key: 'Peppers (Hot)',
    label: 'Peppers (Hot)',
    value: 'Peppers (Hot)',
    id: 23,
  },
  {
    key: 'Peppers (Sweet)',
    label: 'Peppers (Sweet)',
    value: 'Peppers (Sweet)',
    id: 24,
  },
  { key: 'Potatoes', label: 'Potatoes', value: 'Potatoes', id: 25 },
  { key: 'Radishes', label: 'Radishes', value: 'Radishes', id: 26 },
  { key: 'Rhubarb', label: 'Rhubarb', value: 'Rhubarb', id: 27 },
  {
    key: 'Winter Squash',
    label: 'Winter Squash',
    value: 'Winter Squash',
    id: 28,
  },
  {
    key: 'Summer Squash',
    label: 'Summer Squash',
    value: 'Summer Squash',
    id: 29,
  },
  {
    key: 'Sweet Potatoes',
    label: 'Sweet Potatoes',
    value: 'Sweet Potatoes',
    id: 30,
  },
  { key: 'Turnips', label: 'Turnips', value: 'Turnips', id: 31 },
  { key: 'Tomatoes', label: 'Tomatoes', value: 'Tomatoes', id: 32 },
]

exports.FruitsList = [
  { key: 'Apples', label: 'Apples', value: 'Apples', id: 0 },
  { key: 'Apricots', label: 'Apricots', value: 'Apricots', id: 1 },
  { key: 'Avocado', label: 'Avocado', value: 'Avocado', id: 2 },
  { key: 'Berries', label: 'Berries', value: 'Berries', id: 3 },
  { key: 'Cherries', label: 'Cherries', value: 'Cherries', id: 4 },
  { key: 'Citrus', label: 'Citrus', value: 'Citrus', id: 5 },
  { key: 'Dates', label: 'Dates', value: 'Dates', id: 6 },
  { key: 'Grapes', label: 'Grapes', value: 'Grapes', id: 7 },
  { key: 'Melons', label: 'Melons', value: 'Melons', id: 8 },
  { key: 'Nectarines', label: 'Nectarines', value: 'Nectarines', id: 9 },
  { key: 'Peaches', label: 'Peaches', value: 'Peaches', id: 10 },
  { key: 'Persimmon', label: 'Persimmon', value: 'Persimmon', id: 11 },
  { key: 'Pears', label: 'Pears', value: 'Pears', id: 12 },
  { key: 'Plums', label: 'Plums', value: 'Plums', id: 13 },
  { key: 'Strawberries', label: 'Strawberries', value: 'Strawberries', id: 14 },
]
